import * as Location from "expo-location";

import React, { createContext, useReducer, useContext, useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

const LOCATION_EXPIRY_MINUTES = 15;
const MILLISECONDS_PER_MINUTE = 1000 * 60;
const LOCATION_KEY = "location";

export const useLocation = () => {
  return useContext(LocationContext);
};

export enum LocationStatus {
  ACCESS_DENIED,
  LOCATION_LOADING,
  LOCATION_READY,
}

enum ActionKind {
  RESTORE_LOCATION,
  GRANT_ACCESS,
  UPDATE_LOCATION,
}

type LocationState = {
  location?: Location.LocationObject;
  status: LocationStatus;
};

type ContextProps = {
  fetchLocation: () => Promise<LocationState>;
  locationState: LocationState;
};

const LocationContext = createContext<Partial<ContextProps>>({});

type Action =
  | { type: ActionKind.RESTORE_LOCATION; location: Location.LocationObject }
  | { type: ActionKind.GRANT_ACCESS }
  | { type: ActionKind.UPDATE_LOCATION; location: Location.LocationObject };

const locationReducer = (
  prevState: LocationState,
  action: Action
): LocationState => {
  switch (action.type) {
    case ActionKind.RESTORE_LOCATION:
      const location = action.location;
      const minuteDiff =
        (new Date().getTime() - location.timestamp) / MILLISECONDS_PER_MINUTE;

      if (minuteDiff > LOCATION_EXPIRY_MINUTES) {
        return prevState;
      }

      if (
        prevState.location != null &&
        prevState.location.timestamp > location.timestamp
      ) {
        return prevState;
      }

      return {
        ...prevState,
        location: location,
        status: LocationStatus.LOCATION_READY,
      };
    case ActionKind.GRANT_ACCESS:
      if (prevState.status == LocationStatus.ACCESS_DENIED) {
        return { ...prevState, status: LocationStatus.LOCATION_LOADING };
      }
      return prevState;
    case ActionKind.UPDATE_LOCATION:
      return {
        ...prevState,
        location: action.location,
        status: LocationStatus.LOCATION_READY,
      };
    default:
      return prevState;
  }
};

export const LocationProvider = ({ children }) => {
  const [locationState, dispatch] = useReducer(locationReducer, {
    location: null,
    status: LocationStatus.ACCESS_DENIED,
  });

  useEffect(() => {
    if (locationState.location != null) {
      AsyncStorage.setItem(
        LOCATION_KEY,
        JSON.stringify(locationState.location)
      );
    }
  }, [locationState.location]);

  const requestGrant = async () => {
    const { granted } = await Location.getForegroundPermissionsAsync();
    if (!granted) {
      const request = await Location.requestForegroundPermissionsAsync();
      if (request.granted) {
        dispatch({ type: ActionKind.GRANT_ACCESS });
      }
    } else {
      dispatch({ type: ActionKind.GRANT_ACCESS });
    }
  };

  const getPosition = async () => {
    try {
      const loc = await Location.getCurrentPositionAsync({
        accuracy: Location.Accuracy.High,
      });
      if (loc == null) {
        return;
      }
      dispatch({ type: ActionKind.UPDATE_LOCATION, location: loc });
    } catch (ex) {}
  };

  const restorePosition = async () => {
    try {
      const loc = await AsyncStorage.getItem(LOCATION_KEY);
      if (loc != null) {
        dispatch({
          type: ActionKind.RESTORE_LOCATION,
          location: JSON.parse(loc),
        });
      }
    } catch (ex) {
      //
    }
  };

  useEffect(() => {
    (async () => {
      await restorePosition();
      await requestGrant();
      getPosition();
    })();
  }, []);

  const fetchLocation = async () => {
    const { granted } = await Location.getForegroundPermissionsAsync();
    if (!granted) {
      return locationState;
    }
    getPosition();
    return locationState;
  };

  const locationContext = {
    fetchLocation: fetchLocation,
    locationState: locationState,
  };

  return (
    <LocationContext.Provider value={locationContext}>
      {children}
    </LocationContext.Provider>
  );
};
