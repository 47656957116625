import React, { useState } from "react";
import { View, Image, StyleSheet } from "react-native";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Headline, Subheading, Paragraph, useTheme } from "react-native-paper";
import { Button } from "react-native-paper";
import Modal from "../components/modal";
import { useHelp } from "../contexts/help";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";

import "./styles.css";

// import required modules
import { Keyboard, Pagination, Navigation } from "swiper";

function LoginTutorialDeck() {
  const { visible, setModalVisible } = useHelp();
  const styles = useStyles();
  const [isLastSlide, setIsLastSlide] = useState(false);

  return (
    <>
      <Modal
        visible={visible}
        focusOutline={true}
        animate={true}
        setVisible={(v) => {
          setModalVisible(v);
        }}
      >
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          keyboard={{
            enabled: true,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Keyboard, Pagination, Navigation]}
          className="mySwiper"
          observer={true}
          observeParents={true}
          parallax={true}
          justify-contents="center"
          align-items="center"
          onSlideChange={(swiper) => {
            setIsLastSlide(swiper.isEnd);
          }}
          onSwiper={(swiper) => {
            setIsLastSlide(swiper.isEnd);
          }}
        >
          <View style={styles.mainView}>
            <View style={styles.subView}>
              <SwiperSlide>
                <View style={styles.slideView}>
                  <Headline style={styles.largeTextView}>
                    Welcome to Bitdrop!
                  </Headline>
                  <Image
                    style={styles.logo}
                    source={require("../../assets/icon_2.png")}
                  />
                </View>
              </SwiperSlide>
              <SwiperSlide>
                <View style={styles.slideView}>
                  <Subheading style={styles.mediumTextView}>
                    Bitdrop lets you to view files near you within a 1 km
                    radius.
                  </Subheading>
                  <Paragraph style={styles.smallTextView}>
                    Think a more convenient AirDrop.
                  </Paragraph>
                </View>
              </SwiperSlide>
              <SwiperSlide>
                <View style={styles.slideView}>
                  <Subheading style={styles.mediumTextView}>
                    Check out the map.
                  </Subheading>
                  <Image
                    style={styles.largeImage}
                    source={require("../../assets/bitdrop_map.png")}
                  />
                </View>
              </SwiperSlide>
              <SwiperSlide>
                <View style={styles.slideView}>
                  <Subheading style={styles.mediumTextView}>
                    Here you can see what others around have posted around you.
                  </Subheading>
                </View>
              </SwiperSlide>
              <SwiperSlide>
                <View style={styles.slideView}>
                  <Subheading style={styles.mediumTextView}>
                    Sign in to upload your own files.
                  </Subheading>
                  <Image
                    style={styles.longImage}
                    source={require("../../assets/file-icons.png")}
                  />
                </View>
              </SwiperSlide>
              <SwiperSlide>
                <View style={styles.slideView}>
                  <Subheading style={styles.mediumTextView}>
                    You can also view files by clicking "List".
                  </Subheading>
                  <Image
                    style={styles.previewImage}
                    source={require("../../assets/friend-preview.png")}
                  />
                </View>
              </SwiperSlide>
              <SwiperSlide>
                <View style={styles.slideView}>
                  <Subheading style={styles.mediumTextView}>
                    Enjoy using Bitdrop!
                  </Subheading>
                </View>
              </SwiperSlide>
            </View>
          </View>
        </Swiper>
        {isLastSlide ? (
          <Button onPress={() => setModalVisible(false)}>Exit</Button>
        ) : (
          <Button onPress={() => setModalVisible(false)}>Skip</Button>
        )}
      </Modal>
    </>
  );
}

export default LoginTutorialDeck;

const useStyles = () => {
  const { colors } = useTheme();
  const styles = StyleSheet.create({
    mainView: {
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    subView: {
      width: 450,
      height: 300,
    },
    container: {
      paddingTop: 50,
    },
    tinyLogo: {
      width: 50,
      height: 50,
    },
    logo: {
      width: 95,
      height: 95,
      alignSelf: "center",
      marginTop: "10%",
    },
    longImage: {
      alignSelf: "center",
      marginTop: "10%",
      height: 100,
      width: 330,
    },
    largeImage: {
      width: 350,
      height: 200,
      alignSelf: "center",
      marginTop: "10%",
    },
    previewImage: {
      width: 500,
      height: 200,
      alignSelf: "center",
      marginTop: "10%",
    },
    largeTextView: {
      textAlign: "center",
      fontSize: 25,
      color: colors.primary,
    },
    mediumTextView: {
      textAlign: "center",
      fontSize: 20,
      color: colors.primary,
    },
    smallTextView: {
      textAlign: "center",
      fontSize: 18,
      padding: 10,
      color: colors.primary,
    },
    slideView: {
      display: "flex",
      flexDirection: "column",
      width: "70%",
    },
  });
  return styles;
};
