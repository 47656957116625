import { Platform } from "react-native";

export enum AppRoute {
  AUTH_CALLBACK = "AuthCallback",
  HOME = "Home",
  HOME_MAP = "HomeMap",
  HOME_LIST = "HomeList",
  MYFILES = "Files",
  FAVORITES = "Favorites",
  FRIENDS = "Friends",
  FRIEND_PROFILE = "FriendProfile",
}

export function webTitle(text: string) {
  return Platform.select({ web: `${text} - Bitdrop`, default: text });
}
