import * as React from "react";
import AuthButton from "./auth-btn";
import HelpButton from "./header-btn";
import { View } from "react-native";

export default function AppHeaderRight() {
  return (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <HelpButton />
      <AuthButton />
    </View>
  );
}
