import React from "react";
import { View, StyleSheet } from "react-native";
import FileList from "../components/file-list";
import { getMyFiles } from "../util/api";
import { useQuery } from "react-query";
import { ItemCount } from "../components/item-count";
import { useAuthScene } from "./Auth";

function MyFileScene() {
  const { data: files } = useQuery("myfiles", getMyFiles, { initialData: [] });
  return (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        <FileList files={files} />
        <ItemCount itemName="Files" count={files.length} />
      </View>
    </View>
  );
}

export default useAuthScene(MyFileScene);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    flexGrow: 1,
    width: "100%",
    maxWidth: 760,
    marginLeft: "auto",
    marginRight: "auto",
  },
});
