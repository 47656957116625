import * as React from "react";
import { IconButton } from "react-native-paper";

import { useHelp } from "../contexts/help";

export default function HelpButton() {
  const { setModalVisible } = useHelp();
  return (
    <IconButton
      icon="help-circle-outline"
      onPress={() => setModalVisible(true)}
    ></IconButton>
  );
}
