import { FileModel } from "../data/file";
import { Dimensions, StyleSheet } from "react-native";
import { useTheme } from "react-native-paper";

const useStyles = () => {
  const theme = useTheme();
  const styles = StyleSheet.create({
    map: {
      width: "100%",
      height: "100%",
    },
    calloutContainer: {
      flexDirection: "row",
      alignItems: "center",
    },
    linkIcon: {
      height: 16,
      width: 16,
      marginLeft: 5,
      marginRight: 5,
    },
    customCallout: {
      padding: 10,
      backgroundColor: theme.colors.surface,
      borderRadius: 10,
    },
    calloutButtons: {
      marginHorizontal: 5,
    },
  });
  return styles;
};

const LATITUDE_DELTA = 0.0922;

function calculateRegion(lat: number, lon: number) {
  const { width, height } = Dimensions.get("window");
  const aspect_ratio = width / height;
  const region = {
    lat: lat,
    lng: lon,
    latitudeDelta: LATITUDE_DELTA,
    longitudeDelta: LATITUDE_DELTA * aspect_ratio,
  };
  return region;
}
type MapProps = { lat: number; lon: number; files: Array<FileModel> };

export { useStyles, calculateRegion, MapProps };
