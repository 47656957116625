import React, { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import {
  TextInput,
  Avatar,
  Text,
  Button,
  useTheme,
  Title,
  HelperText,
} from "react-native-paper";
//import FileIcon from "./file-icon";
import { getDocumentAsync, DocumentResult } from "expo-document-picker";
import { useFileMutations } from "../util/api";
import { useValidationState } from "../util/validation";
import v8n from "v8n";
import Modal from "./modal-fullpage";
import { useLocation, LocationStatus } from "../contexts/location";
// import { Oval } from "react-loader-spinner";

function UploadModal({
  visible,
  setVisible,
}: {
  visible: boolean;
  setVisible: (visibility: boolean) => void;
}) {
  const theme = useTheme();
  const styles = useStyles();
  const [doc, setDoc] = useState<Extract<
    DocumentResult,
    { type: "success" }
  > | null>(null);
  const [doc_name, onChangeName] = useValidationState<string>(
    "",
    v8n().string().maxLength(50)
  );
  const [desc, onChangeDesc] = useValidationState<string>(
    "",
    v8n().string().maxLength(400)
  );
  const { fetchLocation, locationState } = useLocation();
  const { addFileMutation } = useFileMutations();

  // Resets State on Component Reload
  useEffect(() => {
    if (!visible) {
      setDoc(null);
      onChangeDesc("");
      onChangeName("");
    }
  }, [visible]);

  useEffect(() => {
    fetchLocation();
  }, []);

  const pickDoc = async () => {
    try {
      // What files do we want to limit to be picked
      const res = await getDocumentAsync({
        multiple: false,
        type: "",
      });
      if (res.type === "success") {
        setDoc(res);
        if (doc_name.value === "") {
          onChangeName(res.name);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const uploadDoc = async () => {
    if (locationState.status != LocationStatus.LOCATION_READY) {
      return;
    }
    if (doc == null) {
      return;
    }
    if (!addFileMutation.isLoading) {
      addFileMutation.mutate(
        {
          doc: doc,
          desc: desc.value,
          doc_name: doc_name.value,
          loc: locationState.location,
        },
        {
          onSuccess: () => {
            setVisible(false);
          },
        }
      );
    } else {
      console.log("Upload in progress ...");
    }
  };

  /*
    <FileIcon
      style={styles.fileIcon}
      ext={doc ? doc.name.split(".").pop() : "file"}
    />
   */
  return (
    <React.Fragment>
      <Modal visible={visible}>
        <View style={styles.form}>
          <Title style={styles.modalText}>Upload</Title>
          <Avatar.Icon
            icon="file"
            color={theme.colors.text}
            style={styles.fileIcon}
            size={300}
          />
          <Text numberOfLines={1} style={styles.fileName}>
            {doc ? doc.name : ""}
          </Text>
          <Button
            icon="paperclip"
            style={styles.buttonStyle}
            onPress={() => pickDoc()}
            mode="contained"
          >
            Browse
          </Button>
          {doc ? (
            <>
              <View style={styles.formText}>
                <TextInput
                  mode="flat"
                  style={styles.formTextInput}
                  placeholder="Name"
                  value={doc_name.value}
                  error={!doc_name.ok}
                  onChangeText={onChangeName}
                  maxLength={50}
                />
                <HelperText type="error" visible={!doc_name.ok}>
                  {!doc_name.ok ? "Length too long" : ""}
                </HelperText>
                <TextInput
                  multiline={true}
                  mode="flat"
                  style={styles.formTextInput}
                  placeholder="Description"
                  value={desc.value}
                  error={!desc.ok}
                  onChangeText={onChangeDesc}
                  maxLength={400}
                />
                <HelperText
                  type="error"
                  visible={!desc.ok}
                  style={{ opacity: 1 }}
                >
                  {!desc.ok ? "Length too long" : ""}
                </HelperText>
              </View>
              <Button
                icon="check"
                style={styles.buttonStyle}
                onPress={() => uploadDoc()}
                loading={addFileMutation.isLoading}
                mode="contained"
              >
                Confirm
              </Button>
            </>
          ) : null}
          <Button
            icon="cancel"
            style={styles.buttonStyle}
            onPress={() => setVisible(false)}
            mode="contained"
          >
            Cancel
          </Button>
        </View>
      </Modal>
    </React.Fragment>
  );
}

const useStyles = () => {
  const theme = useTheme();
  const styles = StyleSheet.create({
    formTextInput: {
      backgroundColor: theme.colors.surface,
      margin: 5,
    },
    modalText: {
      fontSize: 54,
      fontWeight: "900",
      fontStyle: "italic",
      textAlign: "center",
      color: theme.colors.text,
    },
    form: {
      width: "100%",
      padding: 20,
      elevation: 3,
      alignItems: "center",
    },
    filePreview: {
      backgroundColor: "rgb(27,27,27)",
      borderRadius: 5,
      color: "white",
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 0,
      },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      padding: 20,
      justifyContent: "space-between",
    },
    fileName: {
      marginTop: -50,
      marginBottom: 30,
      fontSize: 20,
      fontStyle: "italic",
      fontWeight: "400",
    },
    fileIcon: {
      backgroundColor: "rgba(0,0,0,0)",
    },
    rowFlexCenter: {
      flexDirection: "row",
      alignItems: "center",
      flex: 1,
    },
    formText: {
      marginTop: 20,
      marginBottom: 20,
    },
    buttonStyle: {
      marginVertical: 10,
    },
  });
  return styles;
};

export default UploadModal;
