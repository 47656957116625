/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * Generated with the TypeScript template
 * https://github.com/react-native-community/react-native-template-typescript
 *
 * @format
 */

import * as React from "react";
import AppNavigator from "./navigation/app-nav";
import { AppRoute } from "./navigation/routes";
import { AuthProvider } from "./contexts/auth";
import { LocationProvider } from "./contexts/location";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./util/api";
import { HelpContextProvider } from "./contexts/help";
import { NavigationContainer } from "@react-navigation/native";
import { Provider as PaperProvider } from "react-native-paper";
import { useAppTheme } from "./theme";
import {
  useFonts,
  Roboto_100Thin,
  Roboto_300Light,
  Roboto_400Regular,
  Roboto_500Medium,
  Roboto_700Bold,
  Roboto_900Black_Italic,
} from "@expo-google-fonts/roboto";

const config = {
  screens: {
    [AppRoute.AUTH_CALLBACK]: "callback",
  },
};

const linking = {
  prefixes: ["https://bitdrop.app", "bitdrop://"],
  config,
};

const App = () => {
  let [fontsLoaded] = useFonts({
    Roboto_100Thin,
    Roboto_300Light,
    Roboto_400Regular,
    Roboto_500Medium,
    Roboto_700Bold,
    Roboto_900Black_Italic,
  });

  const theme = useAppTheme();
  return (
    <QueryClientProvider client={queryClient}>
      <LocationProvider>
        <HelpContextProvider>
          <AuthProvider>
            <PaperProvider theme={theme}>
              <React.Fragment>
                <NavigationContainer linking={linking} theme={theme}>
                  <AppNavigator />
                </NavigationContainer>
              </React.Fragment>
            </PaperProvider>
          </AuthProvider>
        </HelpContextProvider>
      </LocationProvider>
    </QueryClientProvider>
  );
};

export default App;
