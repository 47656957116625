import React, { ReactNode } from "react";
import { View, Modal, StyleSheet, TouchableOpacity } from "react-native";
import { useTheme, Avatar } from "react-native-paper";

type IProps = {
  visible: boolean;
  setVisible?: (vis: boolean) => void;
  children?: ReactNode;
};
export default function BdModal({ visible, setVisible, children }: IProps) {
  const styles = useStyles();

  return (
    <Modal
      animationType="fade"
      transparent={true}
      presentationStyle="overFullScreen"
      visible={visible}
    >
      <View style={styles.centeredView}>
        <View style={styles.overlay}></View>
        {setVisible != null ? (
          <View style={styles.closeButton}>
            <TouchableOpacity
              onPress={() => {
                setVisible(false);
              }}
            >
              <Avatar.Icon icon="close" size={40} />
            </TouchableOpacity>
          </View>
        ) : null}
        <View style={styles.modalView}>{children}</View>
      </View>
    </Modal>
  );
}

const useStyles = () => {
  const theme = useTheme();
  const styles = StyleSheet.create({
    centeredView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    closeButton: {
      position: "absolute",
      left: "5vw",
      top: "5vw",
      elevation: 3,
    },
    modalView: {
      width: "480px",
      maxWidth: "90%",
      margin: 20,
      alignItems: "center",
      overflow: "hidden",
    },
    overlay: {
      position: "absolute",
      width: "100%",
      height: "100%",
      opacity: 0.9,
      backgroundColor: theme.colors.surface,
    },
  });
  return styles;
};
