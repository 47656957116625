import React, { useEffect } from "react";
import { View, Text, StyleSheet, Platform } from "react-native";
import { AppRoute } from "../navigation/routes";
import { useAuth } from "../contexts/auth";
import * as WebBrowser from "expo-web-browser";
import Warning from "../scenes/Warning";

export function AuthCallback({ route, navigation }) {
  const { params } = route;
  const accessToken = params.accessToken;
  const { signIn } = useAuth();

  const completeWebAuth = () => {
    signIn(accessToken);
  };

  const completeMobileAuth = () => {
    WebBrowser.maybeCompleteAuthSession();
  };

  useEffect(() => {
    Platform.select({ web: completeWebAuth, default: completeMobileAuth })();
    navigation.navigate(AppRoute.HOME);
  }, []);

  return (
    <View>
      <Text>Logging you in</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export function AuthScene({ children }) {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated()) {
    return <>{children}</>;
  } else {
    return (
      <Warning message="Please sign in to use this feature" icon="account" />
    );
  }
}

export function useAuthScene<T>(Component: React.ComponentType<T>) {
  return (props: T) => (
    <AuthScene>
      <Component {...props} />
    </AuthScene>
  );
}
