import React, { useLayoutEffect } from "react";
import { Text, useTheme, IconButton } from "react-native-paper";
import { View, StyleSheet } from "react-native";
import { StackScreenProps } from "@react-navigation/stack";
import { AppNavParams } from "../navigation/app-nav";
import { AppRoute } from "../navigation/routes";
import FileList from "../components/file-list";
import { getUserFiles, getUser, useFriendMutations } from "../util/api";
import { useQuery } from "react-query";
import { FriendModel } from "../data/friend";
import { useAuth } from "../contexts/auth";

type Props = StackScreenProps<AppNavParams, AppRoute.FRIEND_PROFILE>;

export default function FriendProfile({ route, navigation }: Props) {
  const { isAuthenticated } = useAuth();
  const userId = route.params?.userId;
  const { addFriendMutation, removeFriendMutation } = useFriendMutations();

  const removeFriend = (f: FriendModel) => {
    removeFriendMutation.mutate(f);
  };
  const addFriend = (f: FriendModel) => {
    addFriendMutation.mutate(f);
  };

  const { data: user } = useQuery(["user", userId], () => getUser(userId));
  const { data: files } = useQuery(["userfiles", userId], () =>
    getUserFiles(userId)
  );

  const friendButton = () => {
    return (
      <>
        {isAuthenticated() ? (
          <>
            {user && user.isFriend ? (
              <IconButton
                icon="account-check"
                onPress={() => removeFriend(user)}
              />
            ) : (
              <IconButton icon="account-plus" onPress={() => addFriend(user)} />
            )}
          </>
        ) : null}
      </>
    );
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      title: user ? user.name : "Profile",
      headerRight: friendButton,
    });
  }, [navigation, user]);

  return (
    <View style={styles.container}>
      <View style={styles.fileListContainer}>
        <Text style={styles.fileHeader}>Files</Text>
        <FileList files={files} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  fileHeader: {
    fontWeight: "bold",
    color: "#282d2e",
    padding: 5,
    width: "100%",
    textAlign: "center",
  },
  fileListContainer: {
    flexGrow: 1,
    width: "100%",
    maxWidth: 760,
    marginLeft: "auto",
    marginRight: "auto",
  },
});
