import * as React from "react";
import { useNavigation, DrawerActions } from "@react-navigation/native";
import { View, StyleSheet } from "react-native";
import { List } from "react-native-paper";
import { TouchableOpacity } from "react-native-gesture-handler";

export default function DrawerButton() {
  const navigation = useNavigation();
  return (
    <View style={styles.button}>
      <TouchableOpacity
        onPress={() => {
          navigation.dispatch(DrawerActions.toggleDrawer());
        }}
      >
        <List.Icon icon="menu" />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    flexDirection: "row",
    paddingLeft: 10,
  },
  icon: {
    height: 30,
    width: 30,
    resizeMode: "contain",
  },
});
