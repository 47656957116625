import React from "react";
import { View, StyleSheet } from "react-native";
import FileList from "../components/file-list";
import { ItemCount } from "../components/item-count";
import { useLocation, LocationStatus } from "../contexts/location";
import { useQuery } from "react-query";
import { getFiles } from "../util/api";
import { FileModel } from "../data";
import { ProgressBar } from "react-native-paper";
import WarningScreen from "./Warning";

export default function List() {
  const { locationState } = useLocation();
  const { data: files, isLoading, isError } = useQuery<FileModel[], Error>(
    "files",
    locationState.status == LocationStatus.LOCATION_READY
      ? () => getFiles(locationState.location)
      : null,
    { initialData: [] }
  );

  if (isLoading) {
    return <ProgressBar progress={0.8} />;
  }

  if (isError) {
    return <WarningScreen message="Sorry we couldn't load this right now :(" />;
  }

  return (
    <View style={styles.contentContainer}>
      <FileList files={files} />
      <ItemCount itemName="Files Nearby" count={files.length} />
    </View>
  );
}
const styles = StyleSheet.create({
  contentContainer: {
    flexGrow: 1,
    width: "100%",
    maxWidth: 760,
    marginLeft: "auto",
    marginRight: "auto",
  },
});
