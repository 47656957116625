import React from "react";
import { useQuery } from "react-query";
import { getFriends, useFriendMutations } from "../util/api";
import { Text, IconButton, List, Surface } from "react-native-paper";
import { FlatList, View, StyleSheet, TouchableOpacity } from "react-native";
import { FriendModel } from "../data/friend";
import { AppRoute } from "../navigation/routes";
import { ItemCount } from "../components/item-count";
import { useNavigation } from "@react-navigation/native";
import { useAuthScene } from "./Auth";

function FriendsScreen() {
  const { data: friends } = useQuery("friends", getFriends, {
    initialData: [],
  });

  return (
    <View style={[listStyles.contentContainer, listStyles.flexFill]}>
      <FriendList friends={friends} />
      <ItemCount itemName="Friends" count={friends.length} />
    </View>
  );
}

type FriendListProp = {
  friends: Array<FriendModel>;
};

function FriendList({ friends }: FriendListProp) {
  const renderFriend = (result: { item: FriendModel }) => (
    <FriendListItem friend={result.item} />
  );

  return (
    <FlatList
      data={friends}
      renderItem={renderFriend}
      keyExtractor={(friend: FriendModel) => `${friend.id}`}
      contentContainerStyle={listStyles.flexFill}
    />
  );
}

export default useAuthScene(FriendsScreen);

type FriendListItemProp = {
  friend: FriendModel;
};

function FriendListItem({ friend }: FriendListItemProp) {
  const navigation = useNavigation();
  const styles = useStyles();
  const onChoose = () => {
    navigation.navigate(AppRoute.FRIEND_PROFILE, { userId: friend.id });
  };

  const { removeFriendMutation } = useFriendMutations();

  const removeFriend = (f: FriendModel) => {
    removeFriendMutation.mutate(f);
  };

  return (
    <TouchableOpacity onPress={() => onChoose()}>
      <Surface style={styles.listItem}>
        <View>
          <Text style={styles.friendName}>{friend.name}</Text>
          <Text style={styles.friendMeta}>
            {friend.fileCount} files. Last active{" "}
            {dateFormatter.format(new Date(friend.lastActive))}
          </Text>
        </View>
        <View style={styles.friendButtons}>
          <IconButton
            icon="account-check"
            onPress={() => removeFriend(friend)}
          />
          <List.Icon icon="chevron-right" />
        </View>
      </Surface>
    </TouchableOpacity>
  );
}

const dateFormatter = new Intl.DateTimeFormat("en-US", {
  day: "numeric",
  month: "long",
});

const listStyles = StyleSheet.create({
  flexFill: {
    flexGrow: 1,
  },
  contentContainer: {
    width: "100%",
    maxWidth: 760,
    marginLeft: "auto",
    marginRight: "auto",
  },
});

const useStyles = () => {
  const styles = StyleSheet.create({
    listItem: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: 4,
      marginBottom: 4,
      paddingHorizontal: 40,
      paddingTop: 16,
      paddingBottom: 16,
      marginRight: 5,
      marginLeft: 5,
      borderRadius: 4,
      elevation: 2,
    },
    friendName: {
      fontWeight: "500",
      fontSize: 16,
      marginBottom: 2,
    },
    friendMeta: {
      marginTop: 4,
      fontSize: 14,
    },
    friendButtons: {
      flexDirection: "row",
      alignItems: "center",
    },
  });
  return styles;
};
