import React, { createContext, useContext, useState, useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

const HelpContext = createContext<Partial<ContextProps>>({});

export const useHelp = () => {
  return useContext(HelpContext);
};

const FIRST_TIME_VISIT_KEY = "FIRST_TIME_VISIT";

type ContextProps = {
  setModalVisible: (visible: boolean) => void;
  visible: boolean;
};

export const HelpContextProvider = ({ children }) => {
  const [visible, setModalVisible] = useState(true);

  const helpContext = {
    visible: visible,
    setModalVisible: setModalVisible,
  };

  const setFirstTimeLocalStorage = async () => {
    let FIRST_TIME_VISIT_VALUE = await AsyncStorage.getItem(
      FIRST_TIME_VISIT_KEY
    );

    if (FIRST_TIME_VISIT_VALUE == null || FIRST_TIME_VISIT_VALUE == "true") {
      AsyncStorage.setItem(FIRST_TIME_VISIT_KEY, JSON.stringify("false"));
      setModalVisible(true);
    } else {
      setModalVisible(false);
    }
  };

  useEffect(() => {
    setFirstTimeLocalStorage();
  }, []);

  return (
    <HelpContext.Provider value={helpContext}>{children}</HelpContext.Provider>
  );
};
