import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import { Dialog, Button } from "react-native-paper";
import Map from "../components/map";
import Upload from "../components/upload-modal";
import HoverButton from "../components/hover-btn";
import { useLocation, LocationStatus } from "../contexts/location";
import { useAuth } from "../contexts/auth";
import { useQuery } from "react-query";
import { getFiles } from "../util/api";
import { FileModel } from "../data";

export default function MapScreen() {
  const [uploadVisible, setVisible] = useState<boolean>(false);
  const { locationState } = useLocation();
  const { isAuthenticated } = useAuth();

  const { data: files } = useQuery<FileModel[], Error>(
    "files",
    () =>
      locationState.status == LocationStatus.LOCATION_READY
        ? getFiles(locationState.location)
        : null,

    { initialData: [] }
  );

  return (
    <View style={[styles.container, styles.center]}>
      <View style={styles.container}>
        <Upload
          visible={isAuthenticated() && uploadVisible}
          setVisible={setVisible}
        />
        <Map
          lat={locationState.location.coords.latitude}
          lon={locationState.location.coords.longitude}
          files={files}
        />
      </View>
      <Dialog
        visible={!isAuthenticated() && uploadVisible}
        onDismiss={() => setVisible(false)}
      >
        <Dialog.Title>Please sign in to use this feature.</Dialog.Title>
        <Dialog.Actions>
          <Button onPress={() => setVisible(false)}>Done</Button>
        </Dialog.Actions>
      </Dialog>

      <HoverButton
        title="UPLOAD"
        onPress={() => {
          setVisible(true);
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
  },
  center: {
    alignItems: "center",
  },
});
