import React from "react";
import { Text } from "react-native-paper";
import { StyleSheet } from "react-native";

interface IProps {
  count: number;
  itemName: string;
}

export function ItemCount({ count, itemName, ...props }: IProps) {
  return (
    <Text style={styles.itemCount}>
      {count} {itemName}
    </Text>
  );
}
const styles = StyleSheet.create({
  itemCount: {
    width: "100%",
    textAlign: "center",
    fontWeight: "bold",
    opacity: 0.4,
    position: "absolute",
    bottom: 20,
  },
});
