import React from "react";
import {
  View,
  Modal,
  StyleSheet,
  TouchableWithoutFeedback,
} from "react-native";

export default function BdModal(props) {
  return (
    <Modal
      animationType="fade"
      transparent={true}
      presentationStyle="overFullScreen"
      visible={props.visible}
    >
      <View style={styles.centeredView}>
        <TouchableWithoutFeedback
          onPress={() => {
            props.setVisible(false);
          }}
        >
          <View style={styles.overlay}></View>
        </TouchableWithoutFeedback>
        <View style={styles.modalView}>{props.children}</View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  buttonBar: {
    flexDirection: "row",
    width: "100%",
    flex: 1,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    width: "600px",
    maxWidth: "90%",
    height: "400px",
    margin: 20,
    backgroundColor: "#FFF",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    borderRadius: 4,
    overflow: "hidden",
  },
  openButton: {
    backgroundColor: "#000",
    flex: 1,
  },
  textStyle: {
    padding: 16,
    color: "white",
    fontWeight: "500",
    textAlign: "center",
  },
  btnBorderRight: {
    borderRightWidth: 1,
    borderRightColor: "#505050",
  },
  btnBorderLeft: {
    borderLeftWidth: 1,
    borderLeftColor: "#505050",
  },
  overlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    opacity: 0.5,
    backgroundColor: "black",
  },
});
