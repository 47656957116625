import { useState } from "react";
import { V8nObject, ValidationError } from "v8n";

type Result<T, E = Error> =
  | { ok: true; value: T }
  | { ok: false; value: T; error: E };

function useValidationState<T>(
  defaultValue: T,
  validator: V8nObject
): [Result<T, ValidationError>, (arg0: T) => void] {
  const testData = (data: T): Result<T, ValidationError[]> => {
    const test = validator.testAll(data) as ValidationError[];
    if (test.length > 0) {
      return { ok: false, value: data, error: test };
    } else {
      return { ok: true, value: data };
    }
  };

  const [state, setState] = useState<Result<T, ValidationError[]>>(
    testData(defaultValue)
  );
  const setValidationState = (data: T) => {
    setState(testData(data));
  };

  return [state, setValidationState];
}

export { useValidationState, Result };
