import React from "react";
import HybridApp from "./src/App";
import { Platform } from "react-native";
import { enableScreens } from "react-native-screens";
Platform.select({ native: () => enableScreens(), default: () => {} })();

export default class NativeApp extends React.Component {
  render() {
    return <HybridApp />;
  }
}
