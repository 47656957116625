import React from "react";
import { TouchableOpacity } from "react-native";
import { StyleSheet } from "react-native";
import { Text, useTheme, Colors } from "react-native-paper";

export default function HoverButton({
  onPress,
  title,
  buttonStyle = {},
  textStyle = {},
}) {
  const theme = useTheme();
  const localStyles = StyleSheet.create({
    uploadButton: {
      paddingVertical: "10px",
      paddingHorizontal: "30px",
      borderRadius: 20,
      backgroundColor: theme.colors.primary,
      color: theme.colors.text,
      position: "absolute",
      bottom: "30px",
      shadowRadius: 2,
      ...buttonStyle,
    },
    uploadButtonText: {
      fontWeight: "500",
      color: Colors.white,
      fontSize: 16,
      ...textStyle,
    },
  });

  return (
    <TouchableOpacity
      style={localStyles.uploadButton}
      onPress={() => onPress()}
    >
      <Text style={localStyles.uploadButtonText}>{title}</Text>
    </TouchableOpacity>
  );
}
