import React, {
  createContext,
  useReducer,
  useMemo,
  useContext,
  useEffect,
} from "react";
import jwt_decode from "jwt-decode";
import AsyncStorage from "@react-native-async-storage/async-storage";
import API from "../util/api";

interface AuthState {
  isLoading: boolean;
  isSignout: boolean;
  userToken?: string;
}

enum ActionKind {
  RESTORE_TOKEN,
  SIGN_IN,
  SIGN_OUT,
}

type Action =
  | { type: ActionKind.RESTORE_TOKEN; token: string }
  | { type: ActionKind.SIGN_IN; token: string }
  | { type: ActionKind.SIGN_OUT };

const authReducer = (prevState: AuthState, action: Action) => {
  switch (action.type) {
    case ActionKind.RESTORE_TOKEN:
      const newState = {
        ...prevState,
        userToken: action.token,
        isLoading: false,
        isSignout: false,
      };
      return newState;
    case ActionKind.SIGN_IN:
      return {
        ...prevState,
        isSignout: false,
        userToken: action.token,
      };
    case ActionKind.SIGN_OUT:
      return {
        ...prevState,
        isSignout: true,
        userToken: null,
      };
  }
};

type ContextProps = {
  signIn: (tok: string) => Promise<void>;
  signOut: () => Promise<void>;
  isAuthenticated: () => boolean;
  getUserId: () => number;
};

const AuthContext = createContext<Partial<ContextProps>>({});
const ACCESS_TOKEN_KEY = "access";

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [authState, dispatch] = useReducer(authReducer, {
    isLoading: true,
    isSignout: true,
    userToken: null,
  });

  useEffect(() => {
    // Fetch the token from storage then navigate to our appropriate place
    const bootstrapAsync = async () => {
      try {
        const userToken = await AsyncStorage.getItem(ACCESS_TOKEN_KEY);
        if (userToken != null) {
          API.defaults.headers.common["Authorization"] = `Bearer ${userToken}`;
          dispatch({ type: ActionKind.RESTORE_TOKEN, token: userToken });
        }
      } catch (e) {
        // Restoring token failed
      }
    };

    bootstrapAsync();
  }, []);

  const authContext = useMemo(
    () => ({
      signIn: async (tok: string) => {
        // In a production app, we need to send some data (usually username, password) to server and get a token
        // We will also need to handle errors if sign in failed
        // After getting token, we need to persist the token using `SecureStore`
        // In the example, we'll use a dummy token

        API.defaults.headers.common["Authorization"] = `Bearer ${tok}`;
        await AsyncStorage.setItem(ACCESS_TOKEN_KEY, tok);
        dispatch({ type: ActionKind.SIGN_IN, token: tok });
      },
      signOut: async () => {
        API.defaults.headers.common["Authorization"] = "";
        AsyncStorage.removeItem(ACCESS_TOKEN_KEY);
        dispatch({ type: ActionKind.SIGN_OUT });
      },
      isAuthenticated: () => {
        return !authState.isSignout;
      },
      getUserId: () => {
        if (authState.userToken) {
          const tokenData: { id: number } = jwt_decode(authState.userToken);

          return tokenData.id;
        }
        return -1;
      },
    }),
    [authState.isSignout]
  );

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
};
