import {
  LoadScript,
  GoogleMap,
  OverlayView,
  Marker,
} from "@react-google-maps/api";
import { useStyles, calculateRegion, MapProps } from "./map-shared";
import React, { useState } from "react";
import { Text, Caption, Avatar } from "react-native-paper";
import { View, Linking, TouchableOpacity } from "react-native";
import { useMapStyle } from "../theme";
import { GOOGLE_MAPS_API_KEY } from "@env";

const MARKER_LAT_OFFSET = 0.0001;

function StaticMap({ lat, lon, files }: MapProps) {
  const [selected, setSelected] = useState();

  const styles = useStyles();
  const mapStyle = useMapStyle();

  const mapOptions = {
    panControl: false,
    zoomControl: false,
    draggable: false,
    mapTypeControl: false,
    scrollwheel: false,
    streetViewControl: false,
    styles: mapStyle,
  };

  const region = calculateRegion(lat, lon);
  const mapStyles = {
    width: "100%",
    height: "100vh",
  };

  const getPixelPositionOffset = (width, height) => ({
    x: 0,
    y: -height,
  });

  return (
    <View>
      <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={18}
          options={mapOptions}
          center={region}
        >
          {files
            ? files.map((file) => {
                return (
                  <Marker
                    key={file.url}
                    position={{ lat: file.lat, lng: file.lon }}
                    icon={{
                      //scaledSize: new google.maps.Size(50, 50),
                      url: require("../assets/icons/marker-icon.png"),
                    }}
                    onClick={() => setSelected(file)}
                  />
                );
              })
            : null}

          {selected && (
            <OverlayView
              position={{
                lat: selected.lat + MARKER_LAT_OFFSET,
                lng: selected.lon,
              }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              getPixelPositionOffset={getPixelPositionOffset}
            >
              <View style={styles.customCallout}>
                <View style={styles.calloutContainer}>
                  <View
                    style={{
                      paddingVertical: 10,
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <View style={{ marginHorizontal: 10 }}>
                      <Text>{selected.name}</Text>
                      <Caption>{selected.description}</Caption>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignSelf: "center",
                        marginHorizontal: 10,
                      }}
                    >
                      <TouchableOpacity
                        style={styles.calloutButtons}
                        onPress={() => {
                          Linking.openURL(selected.url);
                        }}
                      >
                        <Avatar.Icon size={30} icon="open-in-new" />
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={styles.calloutButtons}
                        onPress={() => {
                          setSelected(null);
                        }}
                      >
                        <Avatar.Icon size={30} icon="close" />
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              </View>
            </OverlayView>
          )}
        </GoogleMap>
      </LoadScript>
    </View>
  );
}
export default StaticMap;
