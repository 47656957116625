import * as React from "react";
import { AppRoute, webTitle } from "./routes";
import { createStackNavigator } from "@react-navigation/stack";
import { createDrawerNavigator } from "@react-navigation/drawer";
import {
  Home,
  MyFiles,
  Friends,
  Favorites,
  FriendProfile,
  AuthCallback,
} from "../scenes";
import DrawerButton from "../components/drawer-button";
import AppHeaderRight from "../components/app-header-right";

type FriendProfileParams = {
  userId: number;
};

export type AppNavParams = {
  [AppRoute.HOME]: undefined;
  [AppRoute.AUTH_CALLBACK]: undefined;
  [AppRoute.FRIEND_PROFILE]: FriendProfileParams;
};

export type AppDrawerParams = {
  [AppRoute.HOME]: undefined;
  [AppRoute.MYFILES]: undefined;
  [AppRoute.FRIENDS]: undefined;
  [AppRoute.FAVORITES]: undefined;
};

const Stack = createStackNavigator<AppNavParams>();
const Drawer = createDrawerNavigator<AppDrawerParams>();

export default function AppNavigator() {
  const homeScreenOptions = {
    headerShown: true,
    headerLeft: ({}) => <DrawerButton />,
    headerRight: ({}) => <AppHeaderRight />,
    title: "Bitdrop",
  };
  return (
    <Stack.Navigator
      initialRouteName={AppRoute.HOME}
      screenOptions={({ route }) => {
        return { title: webTitle(route.name), headerShown: false };
      }}
    >
      <Stack.Screen
        options={homeScreenOptions}
        name={AppRoute.HOME}
        component={AppDrawer}
      />
      <Stack.Screen name={AppRoute.AUTH_CALLBACK} component={AuthCallback} />
      <Stack.Screen
        options={{ headerShown: true }}
        name={AppRoute.FRIEND_PROFILE}
        component={FriendProfile}
      />
    </Stack.Navigator>
  );
}

function AppDrawer() {
  return (
    <Drawer.Navigator
      screenOptions={({ route }) => {
        return { drawerLabel: route.name, title: webTitle(route.name) };
      }}
    >
      <Drawer.Screen name={AppRoute.HOME} component={Home} />
      <Drawer.Screen name={AppRoute.MYFILES} component={MyFiles} />
      <Drawer.Screen name={AppRoute.FRIENDS} component={Friends} />
      <Drawer.Screen name={AppRoute.FAVORITES} component={Favorites} />
    </Drawer.Navigator>
  );
}
