import React, { useEffect } from "react";
import { Platform } from "react-native";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { AppRoute, webTitle } from "../navigation/routes";
import { useLocation, LocationStatus } from "../contexts/location";
import MapScreen from "./Map";
import ListScreen from "./List";
import Warning from "./Warning";
import LoginTutorialDeck from "./LoginTutorialDeck";

type TabBarParams = {
  [AppRoute.HOME_MAP]: undefined;
  [AppRoute.HOME_LIST]: undefined;
};

const TabBar = createMaterialTopTabNavigator<TabBarParams>();
export default function Home() {
  const { fetchLocation, locationState } = useLocation();

  useEffect(() => {
    fetchLocation();
  }, []);

  if (locationState.status != LocationStatus.LOCATION_READY) {
    return (
      <Warning
        icon="crosshairs-gps"
        message="Location not available. Please enable location and try again."
      />
    );
  }

  return (
    <>
      <TabBar.Navigator
        swipeEnabled={Platform.select({ web: false, default: true })}
      >
        <TabBar.Screen
          name={AppRoute.HOME_MAP}
          options={{
            tabBarLabel: "Map",
            title: webTitle("Map"),
          }}
          component={MapScreen}
        />

        <TabBar.Screen
          name={AppRoute.HOME_LIST}
          options={{
            tabBarLabel: "List",
            title: webTitle("List"),
          }}
          component={ListScreen}
        ></TabBar.Screen>
      </TabBar.Navigator>
      <LoginTutorialDeck />
    </>
  );
}
