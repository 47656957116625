import React from "react";
import {
  View,
  FlatList,
  StyleSheet,
  Linking,
  TouchableOpacity,
} from "react-native";
import { Text, useTheme, IconButton, Surface } from "react-native-paper";
import { FileModel } from "../data/file";
import { useNavigation } from "@react-navigation/native";
import { AppRoute } from "../navigation/routes";
import { useFavoriteMutations, useFileMutations } from "../util/api";
import { useAuth } from "../contexts/auth";

type FileListItemProps = {
  file: FileModel;
};

function FileListItem({ file }: FileListItemProps) {
  const navigation = useNavigation();
  const styles = useStyles();
  const theme = useTheme();
  const { isAuthenticated, getUserId } = useAuth();
  const {
    addFavoriteMutation,
    removeFavoriteMutation,
  } = useFavoriteMutations();
  const { removeFileMutation } = useFileMutations();

  const toggleFavorite = (file: FileModel) => {
    if (file.favorite) {
      removeFavoriteMutation.mutate(file);
    } else {
      addFavoriteMutation.mutate(file);
    }
  };

  const onDelete = (id: number) => {
    removeFileMutation.mutate(id);
  };

  return (
    <Surface style={styles.listItem}>
      <View>
        <Text style={styles.listItemHeader}>{file.name}</Text>
        <View style={styles.listItemMeta}>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate(AppRoute.FRIEND_PROFILE, {
                userId: file.posterId,
              });
            }}
          >
            <Text style={styles.listItemMetaAuthor}>{file.posterName} </Text>
          </TouchableOpacity>
          <Text style={styles.listItemMetaText}>
            posted on {dateFormatter.format(new Date(file.postedOn))}
          </Text>
        </View>
      </View>

      <View style={styles.listButtons}>
        {isAuthenticated() && file.posterId == getUserId() ? (
          <IconButton
            color={theme.colors.primary}
            icon="delete-outline"
            onPress={() => {
              onDelete(file.id);
            }}
          />
        ) : null}
        {file.favorite ? (
          <IconButton
            color={theme.colors.primary}
            icon="star"
            onPress={() => {
              toggleFavorite(file);
            }}
          />
        ) : (
          <IconButton
            color={theme.colors.primary}
            icon="star-outline"
            onPress={() => {
              toggleFavorite(file);
            }}
          />
        )}
        <IconButton
          color={theme.colors.primary}
          icon="open-in-new"
          onPress={() => {
            Linking.openURL(file.url);
          }}
        />
      </View>
    </Surface>
  );
}

type FileListProp = {
  files: Array<FileModel>;
};

export default function FileList({ files }: FileListProp) {
  const renderFile = (result: { item: FileModel }) => (
    <FileListItem file={result.item} />
  );

  return (
    <FlatList
      data={files}
      renderItem={renderFile}
      keyExtractor={(file: FileModel) => file.url}
    />
  );
}

const dateFormatter = new Intl.DateTimeFormat("en-US", {
  day: "numeric",
  month: "long",
});

const useStyles = () => {
  const theme = useTheme();
  const styles = StyleSheet.create({
    listItem: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: 4,
      marginBottom: 4,
      paddingHorizontal: 40,
      paddingTop: 16,
      paddingBottom: 16,
      marginRight: 5,
      marginLeft: 5,
      borderRadius: 4,
      elevation: 2,
    },
    listItemHeader: {
      fontSize: 16,
      fontWeight: "500",
      marginBottom: 4,
    },
    listItemMeta: {
      flexDirection: "row",
      marginTop: 2,
      justifyContent: "flex-start",
    },
    listItemMetaAuthor: {
      fontSize: 12,
      color: theme.colors.primary,
      fontWeight: "bold",
    },
    listItemMetaText: {
      fontSize: 12,
      color: "#575757",
    },
    listButtons: {
      flexDirection: "row",
    },
  });
  return styles;
};
