import React from "react";
import { View, StyleSheet } from "react-native";
import { Headline, Avatar } from "react-native-paper";

type IProps = {
  message: string;
  icon?: string;
};
export default function Warning({ message, icon }: IProps) {
  return (
    <View style={styles.container}>
      {icon ? <Avatar.Icon icon={icon} size={80} style={styles.icon} /> : null}
      <Headline>{message}</Headline>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    margin: 30,
  },
});
