import * as React from "react";
import { Platform, Linking } from "react-native";
import { IconButton, Button } from "react-native-paper";
import { BITDROP_API } from "@env";
import * as WebBrowser from "expo-web-browser";
import { useAuth } from "../contexts/auth";

export default function AuthButton() {
  const { signOut, isAuthenticated } = useAuth();

  const signIn = async () => {
    const platform = Platform.select({ web: "web", default: "mobile" });
    if (platform == "web") {
      Linking.openURL(`${BITDROP_API}/auth/google?platform=${platform}`);
    } else {
      WebBrowser.openBrowserAsync(
        `${BITDROP_API}/auth/google?platform=${platform}`
      );
    }
  };

  return isAuthenticated() ? (
    <IconButton onPress={() => signOut()} icon="logout" />
  ) : (
    <Button onPress={() => signIn()}>Sign In</Button>
  );
}
