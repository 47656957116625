import axios from "axios";
import { useMutation, QueryClient } from "react-query";
import { LocationObject } from "expo-location";
import { FileModel, FriendModel } from "../data";
import { dataURItoBlob } from "../util/upload";
import { DocumentResult } from "expo-document-picker";

// import Config from 'react-native-config';
import { BITDROP_API } from "@env";

const api = axios.create({
  baseURL: BITDROP_API,
  responseType: "json",
});
export default api;

export const queryClient = new QueryClient();

export const getFiles = (loc: LocationObject) => {
  return api
    .get<Array<FileModel>>("/files", {
      params: {
        lat: loc.coords.latitude,
        lon: loc.coords.longitude,
      },
    })
    .then((req) => req.data);
};

const removeFile = (id: number) => api.delete(`/files/${id}`);

export const addFile = (
  document: Extract<DocumentResult, { type: "success" }>,
  doc_name: string,
  description: string,
  location: LocationObject
) => {
  const file = dataURItoBlob(document.uri);
  const form = new FormData();
  form.append("file", file, document.name);
  form.append("name", doc_name);
  form.append("description", description);
  form.append("lat", location.coords.latitude.toString());
  form.append("lon", location.coords.longitude.toString());

  return api.post("/files", form, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

type AddFileData = {
  doc: Extract<DocumentResult, { type: "success" }>;
  doc_name: string;
  desc: string;
  loc: LocationObject;
};

const addFileMutation = () =>
  useMutation(
    (data: AddFileData) =>
      addFile(data.doc, data.doc_name, data.desc, data.loc),
    {
      onSettled: () => {
        queryClient.invalidateQueries("files");
        queryClient.invalidateQueries("myfiles");
      },
    }
  );

const removeFileMutation = () =>
  useMutation(removeFile, {
    onMutate: async (fileid: number) => {
      await queryClient.cancelQueries("myfiles");

      // Snapshot the previous value
      const prevFiles: FileModel[] = queryClient.getQueryData("myfiles") || [];

      // Optimistically update to the new value
      queryClient.setQueryData(
        "files",
        prevFiles.filter((f) => f.id != fileid)
      );

      // Return a context object with the snapshotted value
      return { prevFiles };
    },
    onError: (_err, _input, context) => {
      queryClient.setQueryData("myfiles", context.prevFiles);
    },
    onSettled: () => {
      queryClient.invalidateQueries("favorites");
      queryClient.invalidateQueries("files");
      queryClient.invalidateQueries("myfiles");
    },
  });

export const useFileMutations = () => {
  return {
    addFileMutation: addFileMutation(),
    removeFileMutation: removeFileMutation(),
  };
};

export const getMyFiles = () =>
  api.get<Array<FileModel>>("/myfiles").then((req) => req.data);

export const getFavorites = () =>
  api.get<Array<FileModel>>("/favorites").then((req) => req.data);

const addFavorite = (id: number) => api.post(`/favorites/${id}`);
const removeFavorite = (id: number) => api.delete(`/favorites/${id}`);

const favoriteMutationOptions = {
  onMutate: async (file: FileModel) => {
    await queryClient.cancelQueries("files");
    await queryClient.cancelQueries("favorites");

    // Snapshot the previous value
    const prevFiles: FileModel[] = queryClient.getQueryData("files") || [];
    const prevFavs: FileModel[] = queryClient.getQueryData("favorites") || [];

    // Optimistically update to the new value
    queryClient.setQueryData(
      "files",
      prevFiles.map((f) =>
        f.id === file.id ? { ...f, favorite: !f.favorite } : f
      )
    );

    if (prevFavs.some((f) => f.id == file.id)) {
      queryClient.setQueryData(
        "favorites",
        prevFavs.map((f) =>
          f.id === file.id ? { ...f, favorite: !f.favorite } : f
        )
      );
    } else {
      queryClient.setQueryData("favorites", [
        ...prevFavs,
        { ...file, favorite: !file.favorite },
      ]);
    }

    // Return a context object with the snapshotted value
    return { prevFiles, prevFavs };
  },
  onError: (_err, _input, context) => {
    queryClient.setQueryData("files", context.prevFiles);
    queryClient.setQueryData("favorites", context.prevFavs);
  },
  onSettled: () => {
    queryClient.invalidateQueries("files");
    queryClient.invalidateQueries("favorites");
    queryClient.invalidateQueries("myfiles");
  },
};

const addFavoriteMutation = () =>
  useMutation(
    (file: FileModel) => addFavorite(file.id),
    favoriteMutationOptions
  );
const removeFavoriteMutation = () =>
  useMutation(
    (file: FileModel) => removeFavorite(file.id),
    favoriteMutationOptions
  );

export const useFavoriteMutations = () => {
  return {
    addFavoriteMutation: addFavoriteMutation(),
    removeFavoriteMutation: removeFavoriteMutation(),
  };
};

export const getFriends = () =>
  api.get<Array<FriendModel>>("/friends").then((req) => req.data);

export const getUser = (userId: number) =>
  api.get<FriendModel>(`/users/${userId}`).then((req) => req.data);

export const getUserFiles = (userId: number) =>
  api.get<Array<FileModel>>(`/users/${userId}/files`).then((req) => req.data);

const addFriend = (id: number) => api.post(`/friends/${id}`);
const removeFriend = (id: number) => api.delete(`/friends/${id}`);

const friendMutationOptions = {
  onError: (_err, friend, context) => {
    queryClient.setQueryData("friends", context.prevFriends);
    queryClient.setQueryData(["user", friend.id], context.prevFriend);
  },
  onSettled: (_data, _err, friend, _context) => {
    queryClient.invalidateQueries("friends");
    queryClient.invalidateQueries(["user", friend.id]);
  },
};

const addFriendMutation = () =>
  useMutation((friend: FriendModel) => addFriend(friend.id), {
    ...friendMutationOptions,
    onMutate: async (friend: FriendModel) => {
      await queryClient.cancelQueries("friends");
      await queryClient.cancelQueries(["user", friend.id]);

      const prevFriend: FriendModel = queryClient.getQueryData([
        "user",
        friend.id,
      ]);

      // Snapshot the previous value
      const prevFriends: FriendModel[] =
        queryClient.getQueryData("friends") || [];

      queryClient.setQueryData("favorites", [...prevFriends, friend]);
      if (prevFriend != null) {
        queryClient.setQueryData(["user", friend.id], {
          ...friend,
          isFriend: true,
        });
      }
      return { prevFriends, prevFriend };
    },
  });

const removeFriendMutation = () =>
  useMutation((friend: FriendModel) => removeFriend(friend.id), {
    ...friendMutationOptions,
    onMutate: async (friend: FriendModel) => {
      await queryClient.cancelQueries("friends");
      await queryClient.cancelQueries(["user", friend.id]);

      // Snapshot the previous value
      const prevFriend: FriendModel = queryClient.getQueryData([
        "user",
        friend.id,
      ]);
      const prevFriends: FriendModel[] =
        queryClient.getQueryData("friends") || [];

      queryClient.setQueryData(
        "favorites",
        prevFriends.filter((f) => f.id != friend.id)
      );
      if (prevFriend != null) {
        queryClient.setQueryData(["user", friend.id], {
          ...friend,
          isFriend: false,
        });
      }
      return { prevFriends, prevFriend };
    },
  });

export const useFriendMutations = () => {
  return {
    addFriendMutation: addFriendMutation(),
    removeFriendMutation: removeFriendMutation(),
  };
};
